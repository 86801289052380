import { Button, VStack } from '@chakra-ui/react';
import type { NextPage, NextPageContext } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Link from 'next/link';
import { Logo } from '../toolkit/components/Logo';

const Home: NextPage = () => {
  const { t } = useTranslation(['common']);

  return (
    <VStack alignItems='center' justifyContent='center' h='100vh'>
      <Logo size={2} />
      <Link href='/signin' passHref>
        <Button variant='solid'>{t('signin.button')}</Button>
      </Link>
    </VStack>
  );
};

export async function getServerSideProps({ locale }: NextPageContext) {
  return {
    props: {
      ...(await serverSideTranslations(locale || 'de', ['common'])),
    },
  };
}

export default Home;
