import { Box } from '@chakra-ui/react';
import Image from 'next/image';

type LogoProps = {
  size?: number;
  variant?: 'default' | 'grey';
};

export const Logo: React.FC<LogoProps> = ({
  size = 1,
  variant = 'regular',
}) => {
  const w = 100 * size;
  const h = 80 * size;

  return (
    <Box position='relative' h={`${h}px`} w={`${w}px`}>
      <Image
        src={
          variant == 'grey'
            ? '/images/cardy-logo-grey.png'
            : '/images/cardy-logo.png'
        }
        alt='Cardy Logo'
        fill
        style={{ objectFit: 'contain' }}
      />
    </Box>
  );
};
